import React from 'react';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ checkbox, text }) => (
  <Grid container className={styles.checkpointContainer}
    alignItems='center'>
    <Grid xs={1} xl={1} item className={styles.checkboxImageContainer}>
      <Img fluid={checkbox.fluid}
        imgStyle={{ objectFit: 'contain' }}
        objectFit='contain'
        className={styles.image}/>
    </Grid>
    <Grid item xs={11} xl={11}>
      <Typography align='left' variant='h5' weight='light' color='dark'
        className={styles.checkpointText}>
        {i18n(text)}
      </Typography>
    </Grid>
  </Grid>
)