import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper className={styles.container}>
    <Typography
      variant='h2'
      weight='bold'
      color='dark'
      align='left'
      className={styles.title}>
      {i18n('Getting started')}
    </Typography>
    <Grid container xs={12} xl={12} item justifyContent='center' className={styles.cardsContainer}>
      <Grid item xs={12} sm={12} md={4} className={styles.cardWrapper}>
        <div className={styles.cardContainer}>
          <div className={styles.cardTopContainer}>
            <Typography
              variant='subtitle2'
              weight='light'
              align='left'
              color='dark'
              className={styles.step}>
              {i18n('STEP')} 1
            </Typography>
            <Typography
              variant='h3'
              weight='bold'
              align='left'
              color='dark'
              className={styles.cardTitle}>
              {i18n('Sign up in 1 minute')}
            </Typography>
            <Typography
              variant='subtitle2'
              color='highlight'
              align='left'>
              {i18n('All you need is your car\'s license plate.')}
            </Typography>
          </div>
          <div className={styles.cardBottomContainer}>
            <Link to={`${i18n('_url:drivers-pricing')}`}
              style={{ textDecoration: 'none'}}>
              <Button className={styles.button}>
                {i18n('Sign up')}
              </Button>
            </Link>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={styles.cardWrapper}>
        <div className={clsx(
            styles.cardContainer,
            styles.noShadow
          )}>
          <Typography
            variant='subtitle2'
            weight='light'
            align='left'
            color='dark'
            className={styles.step}>
            {i18n('STEP')} 2
          </Typography>
          <Typography
            variant='h3'
            weight='bold'
            align='left'
            color='dark'
            className={styles.cardTitle}>
            {i18n('Complete the registration')}
          </Typography>
          <Typography
            variant='subtitle2'
            color='highlight'
            align='left'>
            {i18n('You will receive a confirmation email to complete your registration.')}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} className={styles.cardWrapper}>
        <div className={clsx(
            styles.cardContainer,
            styles.noShadow
          )}>
          <Typography
            variant='subtitle2'
            weight='light'
            align='left'
            color='dark'
            className={styles.step}>
            {i18n('STEP')} 3
          </Typography>
          <Typography
            variant='h3'
            weight='bold'
            align='left'
            color='dark'
            className={styles.cardTitle}>
            {i18n('Connect and drive')}
          </Typography>
          <Typography
            variant='subtitle2'
            color='highlight'
            align='left'
            className={styles.subtitle}>
            {i18n('Connect your car at your preferred workshop or by yourself. Download the app.')}
          </Typography>
        </div>
      </Grid>
    </Grid>
  </SectionWrapper>
);