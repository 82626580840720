import React from 'react';

export default [({ className, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
    viewBox="0 0 24 24"
    width='100%'
    height='100%'>
    <path className={fill} d="M22.4 9h-1.6a1.8 1.8 0 0 0-1.2.5l-.4.4-.9-1.1A4.4 4.4 0 0 0 14.8 7H9.2a4.4 4.4 0 0 0-3.5 1.8l-1 1.3-.6-.6A2.1 2.1 0 0 0 2.8 9H1.3a.5.5 0 0 0 0 1h1.5l.6.2.6.6-.9.7H3a1.8 1.8 0 0 0-1 1.6V17a2 2 0 0 0 2 2v1a.9.9 0 0 0 1 1h3a.9.9 0 0 0 1-1v-1h6v1a.9.9 0 0 0 1 1h3a.9.9 0 0 0 1-1v-1a2 2 0 0 0 2-2v-3.9a1.8 1.8 0 0 0-1-1.6h-.1a3.9 3.9 0 0 1-1-.8l.4-.5.5-.2h1.6a.5.5 0 0 0 0-1zM15 18H8.8l.4-.5a1.4 1.4 0 0 1 1-.5h3.4a1.4 1.4 0 0 1 1 .5l.5.5zm5.5-5.7h.1a.8.8 0 0 1 .4.7v4a.9.9 0 0 1-1 1 .9.9 0 0 0-1 1v1h-3v-1h1.4a.5.5 0 0 0 0-1h-1l-1.1-1.2a2.3 2.3 0 0 0-1.7-.8h-3.4a2.5 2.5 0 0 0-1.8.8L7.5 18H6.3a.5.5 0 0 0 0 1H8v1H5v-1a.9.9 0 0 0-1-1 .9.9 0 0 1-1-1v-3.9a.8.8 0 0 1 .4-.7h.1a4.7 4.7 0 0 0 1.9-1.5l1.1-1.5A3.2 3.2 0 0 1 9.2 8h5.6a3.2 3.2 0 0 1 2.7 1.4l1.1 1.4a4.7 4.7 0 0 0 1.9 1.5z"/>
    <path className={fill} d="M7 14H5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zM17 11H7a.5.5 0 0 0 0 1h10a.5.5 0 0 0 0-1zM19 14h-2a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zM11.8 4.4a3.1 3.1 0 0 0-2.2.9.5.5 0 1 0 .7.7 2 2 0 0 1 1.5-.6 2 2 0 0 1 1.5.6l.3.2A.5.5 0 0 0 14 6a.5.5 0 0 0 0-.7 3.1 3.1 0 0 0-2.2-.9z"/>
    <path className={fill} d="M14.8 5l.4-.2a.5.5 0 0 0 0-.7 4.8 4.8 0 0 0-6.8 0 .5.5 0 0 0 .7.7 3.8 3.8 0 0 1 5.4 0z"/>
    <path className={fill} d="M7.9 3.6a5.6 5.6 0 0 1 7.8 0l.3.2.4-.2a.5.5 0 0 0 0-.7 6.6 6.6 0 0 0-9.2 0 .5.5 0 0 0 .7.7z"/>
  </svg>
), ({ className, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    {...props}
    width='100%'
    height='100%'>
    <path className={fill} d="M19 21.6V8.1a6 6 0 0 0-6-6h-2A5.9 5.9 0 0 0 5 8v2l-1 2a.9.9 0 0 0 1 1v3a2.9 2.9 0 0 0 2.9 3H9v1a2.4 2.4 0 0 1-.7 1.7L6 24h1.4l1-1 .6-.6a3.5 3.5 0 0 0 1-2.4v-2H7.9A1.9 1.9 0 0 1 6 16.1V12h-.9l.8-1.5V8a5 5 0 0 1 5-4.9h2a5 5 0 0 1 5 5v13.5a5.9 5.9 0 0 0 .2 1.4l.5 1H20a3.1 3.1 0 0 1-1-2.4z"/>
    <path className={fill} d="M9 15a.9.9 0 0 1-1-1H7a2 2 0 0 0 4 0h-1a1 1 0 0 1-1 1zM12.5 9.1A3.9 3.9 0 0 0 9 7H8a4 4 0 0 0 4 4v2h1v-2a4 4 0 0 0 4-4h-1a3.9 3.9 0 0 0-3.5 2.1zM9.2 8a3 3 0 0 1 2.6 2 2.9 2.9 0 0 1-2.6-2zm4 2a2.9 2.9 0 0 1 2.6-2 3 3 0 0 1-2.6 2z"/>
  </svg>
), ({ className, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    {...props}
    width='100%'
    height='100%'>
    <path className={fill} d="M19.5 17a2.5 2.5 0 0 0-2.4 2h-6.7A1.4 1.4 0 0 0 9 18a1.5 1.5 0 0 0-1.4 1H5.5a2.5 2.5 0 0 1 0-5h5.1a1.5 1.5 0 0 0 1.4 1 1.4 1.4 0 0 0 1.4-1h5.1a3.5 3.5 0 0 0 0-7h-2.1a1.5 1.5 0 0 0-2.8 0H7a2.4 2.4 0 0 0-.7-1.2A4.5 4.5 0 0 0 7 3.5 2.5 2.5 0 0 0 4.5 1 2.5 2.5 0 0 0 2 3.5a4.2 4.2 0 0 0 .7 2.3A2.4 2.4 0 0 0 2 7.5a2.6 2.6 0 0 0 2.5 2.6A2.6 2.6 0 0 0 7 8h6.6A1.5 1.5 0 0 0 15 9a1.4 1.4 0 0 0 1.4-1h2.1a2.5 2.5 0 0 1 2.5 2.5 2.5 2.5 0 0 1-2.5 2.5h-5.1a1.4 1.4 0 0 0-1.4-1 1.5 1.5 0 0 0-1.4 1H5.5a3.5 3.5 0 0 0 0 7h2.1A1.5 1.5 0 0 0 9 21a1.4 1.4 0 0 0 1.4-1h6.7a2.5 2.5 0 1 0 2.4-3zM4.5 2A1.5 1.5 0 0 1 6 3.5a4.5 4.5 0 0 1-1.5 2.9A4.2 4.2 0 0 1 3 3.5 1.5 1.5 0 0 1 4.5 2zm0 7.1A1.6 1.6 0 0 1 3 7.5a1.4 1.4 0 0 1 .3-.9l.9.8.3.2.3-.2.9-.8a1.4 1.4 0 0 1 .3.9 1.5 1.5 0 0 1-1.5 1.6zm15 11.9a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z"/>
  </svg>
), ({ className, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    {...props}
    width='100%'
    height='100%'>
    <path className={fill} d="M17.5 7.5C16.1 7.5 15 8.6 15 10v5h1v-5c0-.8.7-1.5 1.5-1.5H21v-1h-3.5zM7.1 3.5c-.5-.3-1.1 0-1.1.6v1.8c0 .6-.4 1-1 1s-1-.4-1-1V4.2c0-.6-.6-1-1.1-.7-1.1.7-1.8 1.9-1.8 3.3s.8 2.7 1.9 3.4v8.6c0 1 .7 2 1.7 2.1H5c1.1 0 2-.9 2-2v-8.7c1.1-.7 1.9-1.9 1.9-3.4 0-1.4-.7-2.7-1.8-3.3zm-.6 5.8l-.5.3V18.9c0 .6-.4 1-1 1h-.2c-.4-.1-.8-.6-.8-1.1V9.6l-.5-.3c-.9-.5-1.4-1.5-1.4-2.5 0-.8.3-1.6.9-2.1v1c0 1 .7 2 1.7 2.1C5.9 8 7 7.1 7 5.9V4.7c.6.5.9 1.3.9 2.1 0 1-.5 2-1.4 2.5z"/>
    <path className={fill} d="M18.9 11.1c-.6 0-1.1-.5-1.1-1.1h-1c0 1.1.9 2.1 2.1 2.1s2.1-1 2.1-2.1h-1c0 .6-.5 1.1-1.1 1.1zM14 10h-1c0 .6-.5 1-1 1-.6 0-1-.5-1-1h-1c0 1.1.9 2 2 2s2-.9 2-2zM15 18c-1.3 0-2.4-.9-2.8-2.1h-1c.4 1.8 1.9 3.1 3.8 3.1s3.4-1.3 3.8-3.1h-1c-.4 1.2-1.5 2.1-2.8 2.1z"/>
  </svg>
)];