import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Typography from '../../../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  Tile,
  selected = false,
  title,
  text,
  onClick = () => {},
}) => (
  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}
    onClick={onClick}
    className={clsx(
      styles.container,
      selected ? styles.containerSelected : ''
    )}>
    <Grid item xs={0} sm={2} xl={2}>
      <Tile selected={selected}
        className={styles.imageContainer}
        fill={styles.stroke}/>
    </Grid>
    <Grid item xs={12} sm={10} xl={10} className={styles.textWrapper}>
      <Typography variant='h4' weight='bold' color='dark'
        align='left'
        className={styles.title}>
        {title}
      </Typography>
      <Typography variant='subtitle2' weight='light' color='dark'
        align='left'
        className={styles.text}>
        {text}
      </Typography>
    </Grid>
  </Grid>
);