import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "Drive-Logo-Text-2x.png"}}) {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
        hero: imageSharp (fluid: {
          originalName: { eq: "Driver-Hero-new.jpg" }
        }) {
          fluid(maxWidth: 1080, maxHeight: 719, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero, logo }) => (
      <SectionWrapper className={styles.container}>
        {/* <Grid item className={styles.splashContainer}
          xs={12} sm={12} md={12} lg={12}>
          <Img imgStyle={{ objectFit: 'cover' }}
            objectFit='cover'
            className={styles.imageContainer}
            fluid={background.fluid}
            alt='OBI+ Fleet'/>
        </Grid> */}
        <Grid container xs={12} xl={12} item justifyContent='space-between'>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.heroImageContainerMobile}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='top right'
              className={styles.imageContainer}
              fluid={hero.fluid}
              alt={i18n('Car application for every driver')}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} className={styles.overviewContainer}
            direction='column'
            justifyContent='center'>
            <Img imgStyle={{ objectFit: 'none' }}
              objectFit='none'
              objectPosition='left center'
              className={styles.imageLogo}
              fluid={logo.fluid}
              alt='OBI+ Fleet'/>
            <Typography
              variant='h1'
              color='dark'
              weight='bold'
              className={clsx(
                styles.title,
                styles.heroText
              )}>
              {i18n('Your car\'s best friend')}
            </Typography>
            <Typography
              variant='h4'
              color='dark'
              weight='light'
              className={clsx(
                styles.subtitle,
                styles.heroText
              )}>
              {i18n('Safer driving. Convenient experience.')}
            </Typography>
            <div className={styles.heroText}>
              <Link to={`${i18n('_url:drivers-pricing')}`}
                className={styles.heroText}>
                <Button className={styles.button}>
                  {i18n('Sign up')}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.heroImageContainerWide}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='top right'
              className={styles.imageContainer}
              fluid={hero.fluid}
              alt={i18n('Car application for every driver')}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )
  }/>
);