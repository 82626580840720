import React from 'react';

const Checkmark =  () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24">
    <path fill="#4069f8" d="M9 20l-7-7 2-2 5 5L20 5l2 2L9 20z"/>
  </svg>
);

const Xmark = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24" data-name="Layer 3">
    <path fill="#e66a3b" d="M 20 6 l -2 -2 l -6 6 l -6 -6 l -2 2 l 6 6 l -6 6 l 2 2 l 6 -6 l 6 6 l 2 -2 l -6 -6 l 6 -6 Z" />
  </svg>
);

export default ({ className, check = false }) => (
  <div className={className}>
    {check ? <Checkmark/>
      : <Xmark/>}
  </div>
);