import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Typography from '../Typography';
import styles from './styles.module.sass';

const Title = ({ 
  children,
  variant = 'h2',
  weight = 'regular',
  align = 'center',
  color = 'dark',
  containerClass = '',
  className = ''
}) => (
  <Grid item md={12}
    className={clsx(
      styles.container,
      containerClass
    )}>
    <Typography variant={variant} 
      weight={weight}
      color={color} 
      align={align}
      className={className}>
      {children}
    </Typography>
  </Grid>
);

export default Title;