import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import i18n from '../../../../locale';
import Step from './Entry';
import Logbook from './Logbook';
import styles from './styles.module.sass';

const $REPAIRS = 0;
const $LOGBOOK = 1;

export default () => {
  const [tab, setTab] = React.useState($REPAIRS);

  return (
    <StaticQuery
      query={graphql`
        query {
          arrow: imageSharp(fluid: {
            originalName: {eq: "Driver-Step-Arrow-2x.png"}
          }) {
            fluid(maxWidth: 41, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          old1: imageSharp(fluid: {
            originalName: {eq: "Compare-old-1.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          old2: imageSharp(fluid: {
            originalName: {eq: "Compare-old-2.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          old3: imageSharp(fluid: {
            originalName: {eq: "Compare-old-3.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          old4: imageSharp(fluid: {
            originalName: {eq: "Compare-old-4.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          old5: imageSharp(fluid: {
            originalName: {eq: "Compare-old-5.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          old6: imageSharp(fluid: {
            originalName: {eq: "Compare-old-6.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          new1: imageSharp(fluid: {
            originalName: {eq: "Compare-new-1.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          new2: imageSharp(fluid: {
            originalName: {eq: "Compare-new-2.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          new3: imageSharp(fluid: {
            originalName: {eq: "Compare-new-3.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          new4: imageSharp(fluid: {
            originalName: {eq: "Compare-new-4.png"}
          }) {
            fluid(maxWidth: 180, maxHeight: 180, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      `}
      render={({
        old1, old2, old3, old4, old5, old6,
        new1, new2, new3, new4,
        arrow,
      }) => (
        <SectionWrapper className={styles.container}>
          <Typography color='dark' variant='h3' align='left' weight='bold'
            className={styles.title}>
            {i18n('How is our service different?')}
          </Typography>
          <Typography color='highlight' variant='h4' align='left' weight='bold'
            className={styles.subtitle}>
            {i18n('Compare')}
          </Typography>
          <div className={styles.buttonsContainer}>
            <Button className={clsx(
                styles.button,
                tab === $LOGBOOK && styles.buttonUnselected
              )}
              onClick={() => setTab($REPAIRS)}>
              {i18n('Service & repair experience')}
            </Button>
            <Button className={clsx(
                styles.button,
                tab === $REPAIRS && styles.buttonUnselected
              )}
              onClick={() => setTab($LOGBOOK)}>
              {i18n('Logbook service')}
            </Button>
          </div>
          {tab === $REPAIRS
          ? <Grid item container className={styles.timelineContainer}>
              <Grid item container justifyContent='space-between' md={6} lg={6} xl={6}
                direction='column'
                className={styles.column}>
                <Grid item className={styles.initialSteps}>
                  <Typography color='dark' variant='h3' align='left' weight='bold'
                    className={styles.columnTitle}>
                    {i18n('The old way')}
                  </Typography>
                  <Step img={old1}
                    title={i18n('Service light or engine light on.')}
                    text={i18n('The car tells me to do something. I\'ll do it later.')}/>
                  <Step img={old2}
                    title={i18n('30 day later. There is something to do about my car.')}
                    text={i18n('Time sure flies. Should have left a note.')}/>
                  <Step img={old3}
                    title={i18n('I should remember to call the workshop')}
                    text={i18n('Something should be done.')}/>
                  <Step img={old4}
                    title={i18n('Checking workshop\'s available hours. They work until 16:30 ... it\'s 17:00 now.')}
                    text={i18n('Finally something is to be done.')}/>
                  <Step img={old5}
                    title={i18n('3 days later. I need to call or visit. Might need to take time off from work.')}
                    text={i18n('Too much work... wondering why it takes so long.')}/>
                </Grid>
                <Grid item className={styles.finalSteps} >
                  <Step img={old6}
                    final
                    title={i18n('33+ days later. Car is finally at the shop.')}
                    text=''/>
                </Grid>
              </Grid>
              <Grid item container justifyContent='space-between' md={6} lg={6} xl={6}
                direction='column'
                className={clsx(
                  styles.column,
                  styles.columnRight
                )}>
                <Grid item className={styles.initialSteps}>
                  <Typography color='dark' variant='h3' align='left' weight='bold'
                    className={styles.columnTitle}>
                    {i18n('The new way')}
                  </Typography>
                  <Step img={new1}
                    title={i18n('Service light or engine light on.')}
                    text={i18n('The car tells me to do something. My phone will handle it or remind me later.')}/>
                  <Step img={new2}
                    title={i18n('Workshop is notified right away')}
                    text={i18n('Something is being done already.')}/>
                  <Step img={new3}
                    title={i18n('I don\'t need to worry')}
                    text={i18n('You are contacted by your workshop with information about it and agree on a convenient time for you to visit.')}/>
                  <Step img={arrow}
                    title={null}
                    text={null}/>
                  <Step img={arrow}
                    title={null}
                    text={null}/>
                </Grid>
                <Grid item className={styles.finalSteps}>
                  <Step img={new4}
                    final
                    title={i18n('All is settled in 1 day. Peace of mind.')}
                    text={<>
                      - {i18n('Avoid making the issue more severe with time')}<br/>
                      - {i18n('Minimal efforts and hassle-free experience')}<br/>
                      - {i18n('You know if it\'s safe to drive meanwhile')}<br/>
                    </>}/>
                </Grid>
              </Grid>
            </Grid>
            : <Logbook/>}
        </SectionWrapper>
      )}/>
  );
};