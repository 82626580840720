import React from 'react';
import { Grid } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import WhyIcon from './WhyIcon';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        img1: imageSharp(fluid: {
          originalName: {eq: "Driver-Pitch-1.jpg"}
        }) {
          fluid(maxWidth: 240, maxHeight: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        img2: imageSharp(fluid: {
          originalName: {eq: "Driver-Pitch-2.jpg"}
        }) {
          fluid(maxWidth: 240, maxHeight: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        img3: imageSharp(fluid: {
          originalName: {eq: "Driver-Pitch-3.jpg"}
        }) {
          fluid(maxWidth: 240, maxHeight: 240, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ img1, img2, img3 }) => (
      <SectionWrapper className={styles.container}>
        <Grid item container className={styles.contentContainer} xs={12} xl={12}
          justifyContent='center'>
          <WhyIcon fluid={img1.fluid}
            text={i18n('Connected car')}
            subtext={i18n('Innovative technology delivered in a simple and affordable way.')}/>
          <Typography variant='h3'
            color='highlight'
            className={styles.plusContainer}>
            +
          </Typography>
          <WhyIcon fluid={img2.fluid}
            text={i18n('Car app')}
            subtext={i18n('A better way to keep track of your car.')}/>
          <Typography variant='h3'
            color='highlight'
            className={styles.plusContainer}>
            +
          </Typography>
          <WhyIcon fluid={img3.fluid}
            text={i18n('Smart link')}
            subtext={i18n('Peace of mind and minimal effort on car-related tasks')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);