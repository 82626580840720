import React from 'react';
import Subheader from '../../../components/Header/navmap/driver';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Pitch from './Pitch';
import Overview from './Overview';
import Advantages from './Advantages';
import Compare from './Compare';
import GettingStarted from './GettingStarted';
import FAQ from './FAQ';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries}
    subpage={sitemap.driver._}
    subheader={Subheader}
    url='_url:drivers'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Pitch/>
    <Overview/>
    <Advantages/>
    <Compare/>
    <GettingStarted/>
    <FAQ/>
  </Layout>
);